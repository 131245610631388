
html, body {
  height: 100%;
}

main {
  min-height: calc(100vh - 112px);
}

footer {
  height: 110px;
}
@media only screen and (max-width: 770px) {
  footer {
    height: 135px;
  }

}

@media only screen and (max-width: 600px) {
  footer {
    height: 242px;
  }

}

.bg-dark {
  background-color: #000 !important;
}

h1 {
  font-weight: lighter;
  font-size: 2.5rem;
}



@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }
}

a.light {
  color: #000 !important;
}

.dark {
  background-color: #000 !important;
  color: #fff;
}

.dark a {
  color: #fff;
}

.light a {
  color: #000;
}

.light {
  color: #000;
}

.dark hr {
  border-top: 1px solid #333;
}

.light hr {
  border-top: 1px solid #e6e6e6
}

.ventures {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

}

.ventures::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.ventures .title-text {
  font-size: 31px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  line-height: 38px;
  pointer-events: none;
  z-index: 2;
}

.ventures .zoom-icon {
  font-size: 1.75rem;
  z-index: 2;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

}

@media only screen and (max-width: 770px) {
  .ventures .zoom-icon {
    top: 35%;
  }
}

@media only screen and (max-width: 600px) {
  .ventures .zoom-icon {
    top: 42%;
  }
}



.ventures:hover {

}

.ventures:hover .rollover {
  transform: scale(1.04);

  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 5;
}

.ventures:hover .rollover hr {

  width: 100px;
}

.rollover {
  -webkit-transition: transform 0.6s ease-out;
  -moz-transition: transform .6s ease-out;
  transition: transform .6s ease-out;
  opacity: 0;

}

.ventures .rollover .rollover-text {
  color: transparent;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  z-index: 2;
  font-size: 18px;
  line-height: 26px;
}

.ventures:hover .rollover .rollover-text {
  color: #000;

}

.rollover-text .more {
  font-weight: bold;
  display: block;
  padding-top: 10px;
}

.rollover hr {
  width: 0;
  -webkit-transition: width 0.6s ease-out;
  -moz-transition: width .6s ease-out;
  transition: width .6s ease-out;

}

.padding-top {
  padding-top: 85px;
}

.padding-bottom {
  padding-bottom: 85px;
}

.icon {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  border: 2.5px solid #fff;
  border-radius: 100%;
  text-align: center;
}

.icon::after {
  display: block;
  position: absolute;
  bottom: -50px;
  left: 50%;
  content: '';
  width: 2.5px;
  height: 50px;
  background: #fff;
}

.what-we-do-box h5 {
  position: relative;
  padding-top: 3.5rem !important;
}

.icon img {
  padding: 2rem;
}

.icon:hover {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;

}

.icon:hover .rollover .rollover-text {
  color: #000;
}

.icon .rollover {
  opacity: 0;
}

.icon:hover .rollover {
  transform: scale(1.25);

  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: #000 !important;
  z-index: 5;
}

.icon .rollover .rollover-text {
  color: transparent;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 2;
  font-size: 12px;
  line-height: 20px;
}

.icon .rollover hr {
  border-top: 1px solid #000;
}

.icon:hover .rollover hr {
  width: 50px;
}

.dark-modal {
  background-color: #262626;
}

.dark-modal .close {
  color: #fff;
}

.dark-modal .modal-header {
  border-bottom: 1px solid #dee2e6;
}

.dark-modal {
  color: #fff;
}

.navbar-brand {
  margin: 0 auto;
  img {
    height: 150px;
  }
}

@media only screen and (max-width: 770px) {
  .navbar-brand {
    img {
      height: 150px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar-brand {
    img {
      height: 125px;
    }
  }
}

#introduction {
  position: relative;
  text-align: center;
  background-image: url('../../imgs/intro-tile.jpg');
  height: 780px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

#clients {
  position: relative;
  text-align: center;
  height: 377px;
  width: 100%;
  background-image: url('../../imgs/clients-tile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#deliverables {
  position: relative;
  text-align: center;
  height: 377px;
  width: 100%;
  background-image: url('../../imgs/deliverables-tile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#industries {
  position: relative;
  text-align: center;
  height: 377px;
  width: 100%;
  background-image: url('../../imgs/industries-tile.jpg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#people {
  position: relative;
  text-align: center;
  height: 377px;
  width: 100%;
  background-image: url('../../imgs/industries-tile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 770px) {

  #introduction {
    padding: 12rem 0;
    height: auto;

  }

  #clients {
    padding: 12rem 0;
    height: auto;
  }

  #deliverables {
    padding: 13rem 0;
    height: auto;
  }

  #industries {
    padding: 14rem 0;
    height: auto;
  }

  #people {
    padding: 10rem 0;
    height: auto;
  }

}

@media only screen and (max-width: 600px) {

  #introduction {
    padding: 27rem 0;

  }

  #clients {
    padding: 23rem 0;
  }

  #deliverables {
    padding: 25rem 0;
  }

  #industries {
    padding: 20rem 0;

  }

  #people {
    padding: 15rem 0;

  }

}

/* Slider */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  position: relative;
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.slick-slide::after {

}

.slick-slide:hover {
  cursor: pointer;

}

.slick-slide img:hover {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out;

}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

//Form

.form-control,
.form-group .form-control {
  border: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  -webkit-background-size: 0 2px, 100% 1px;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0s ease-out;
  -o-transition: background 0s ease-out;
  transition: background 0s ease-out;
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
  color: #BDBDBD;
  font-weight: 400
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 400
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
  color: #BDBDBD;
  font-weight: 400
}

.form-control[disabled],
.form-control[readonly],
.form-group .form-control[disabled],
.form-group .form-control[readonly],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-color: rgba(0, 0, 0, 0)
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #D2D2D2
}

.form-group {
  position: relative;
  transition: all 0.3s ease-in-out;

}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-static label.control-label {
  position: absolute;
  pointer-events: none;
  -webkit-transition: .3s ease all;
  -o-transition: .3s ease all;
  transition: .3s ease all
}

.form-group.label-floating label.control-label {
  will-change: left, top, contents
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none
}

.form-group .help-block {
  position: absolute;
  display: none
}

.form-group.is-focused .form-control {
  outline: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  -webkit-background-size: 100% 2px, 100% 1px;
  background-size: 100% 2px, 100% 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s
}

.form-group.is-focused .form-control .material-input:after {
  background-color: #009688
}

.form-group.is-focused label,
.form-group.is-focused label.control-label {
  color: #009688
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
  color: #BDBDBD
}

.form-group.is-focused .help-block {
  display: block
}

.form-group.has-warning .form-control {
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-group.has-warning.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#ff5722)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#ff5722, #ff5722), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#ff5722, #ff5722), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-warning .help-block,
.form-group.has-warning label.control-label {
  color: #ff5722
}

.form-group.has-error .form-control {
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-group.has-error .help-block,
.form-group.has-error label.control-label {
  color: #f44336
}

.form-group.has-success .form-control {
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-group.has-success.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4caf50), to(#4caf50)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#4caf50, #4caf50), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#4caf50, #4caf50), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-success .help-block,
.form-group.has-success label.control-label {
  color: #4caf50
}

.form-group.has-info .form-control {
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-group.has-info.is-focused .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#03a9f4), to(#03a9f4)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#03a9f4, #03a9f4), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#03a9f4, #03a9f4), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2)
}

.form-group.has-info .help-block,
.form-group.has-info label.control-label {
  color: #03a9f4
}

.form-group textarea {
  resize: none
}

.form-group textarea ~ .form-control-highlight {
  margin-top: -11px
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.form-group select ~ .material-input:after {
  display: none
}

.form-control {
  margin-bottom: 7px
}

.form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.checkbox label,
.radio label,
label {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
  font-weight: 400;
  margin: 16px 0 0 0
}

.help-block {
  margin-top: 0;
  font-size: 12px
}

.form-group {
  padding-bottom: 7px;
  margin: 28px 0 0 0
}

.form-group .form-control {
  margin-bottom: 7px
}

.form-group .form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-group .form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
  font-size: 16px;
  line-height: 1.42857143;
  color: #BDBDBD;
  font-weight: 400
}

.form-group label.control-label {
  font-size: 12px;
  line-height: 0.071429;
  font-weight: 400;
  margin: 16px 0 0 0
}

.form-group .help-block {
  margin-top: 0;
  font-size: 12px
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 16px;
  line-height: 0.071429;

}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
  top: -30px;
  left: 0;
  font-size: 16px;
  line-height: 0.071429;
}

.form-group.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857
}

.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0 0
}

.form-group.form-group-sm .form-control {
  margin-bottom: 3px
}

.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
  font-size: 11px;
  line-height: 1.5;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
  font-weight: 400;
  margin: 16px 0 0 0
}

.form-group.form-group-sm .help-block {
  margin-top: 0;
  font-size: 9px
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 11px;
  line-height: 1.5
}

.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-sm.label-static label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125
}

.form-group.form-group-sm.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125
}

.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 30px 0 0 0
}

.form-group.form-group-lg .form-control {
  margin-bottom: 9px
}

.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
  font-size: 18px;
  line-height: 1.3333333;
  color: #BDBDBD;
  font-weight: 400
}

.form-group.form-group-lg label.control-label {
  font-size: 14px;
  line-height: .99999998;
  font-weight: 400;
  margin: 16px 0 0 0
}

.form-group.form-group-lg .help-block {
  margin-top: 0;
  font-size: 14px
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 18px;
  line-height: 1.3333333
}

.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-lg.label-static label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: .99999998
}

.form-group.form-group-lg.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: .99999998
}

select.form-control {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0
}

.form-group.is-focused select.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #D2D2D2
}

.form-group.is-focused select.form-control[multiple],
select.form-control[multiple] {
  height: 85px
}

.input-group-btn .btn {
  margin: 0 0 7px 0
}

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0
}

.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0
}

.input-group .input-group-btn {
  padding: 0 12px
}

.input-group .input-group-addon {
  border: 0;
  background: 0 0
}

.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100
}

.form-group {
  .form-control {
    padding-left: 0;
  }

  input[type=text]:focus, textarea:focus {
    background-image: linear-gradient(#fff, #fff),linear-gradient(#fff, #fff);
    color: #fff;
  }

  input[type=email]:focus {
    background-image: linear-gradient(#fff, #fff),linear-gradient(#fff, #fff);
    color: #fff;

  }


}

.form-submit .btn {
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.form-submit .btn:hover {
  opacity: 0.8;

}

.contact-widget-section .single-contact-widget {
  background: #f9f9f9;
  padding: 20px 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  height: 260px;
  margin-top: 25px;
  transition: all 0.3s ease-in-out
}

.contact-widget-section .single-contact-widget i {
  font-size: 75px
}

.contact-widget-section .single-contact-widget h3 {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  padding-bottom: 10px
}

.contact-widget-section .single-contact-widget p {
  line-height: 16px
}

.contact-widget-section .single-contact-widget:hover {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
  cursor: pointer;
  transition: all 0.3s ease-in-out
}

#contactForm {
  margin-top: -10px
}

#contactForm .form-group label.control-label {
  color: #8c8c8c
}

#contactForm .form-control {
  font-weight: 500;
  height: auto
}